// this extracts the logic to change the page title based on the translation
export default function usePageTitle(pageTitle: ComputedRef<string> | string) {
  const title = ref(unref(pageTitle))
  useHead({ title: title.value })

  const { locale } = useI18n({
    useScope: 'local',
  })

  const route = useRoute()

  watch(
    locale,
    () => {
      route.meta.title = title.value
    },
    {
      immediate: true,
    },
  )
}
